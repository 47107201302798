var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-content',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"header":_vm.$t('automatic.plan.name'),"description":_vm.$t('automatic.plan.description')}},[_c('complex-table',{attrs:{"data":_vm.data,"pagination-config":_vm.paginationConfig,"search-config":_vm.searchConfig,"selects":_vm.selects},on:{"search":_vm.search,"update:selects":function($event){_vm.selects=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-button-group',[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['ADMIN']),expression:"['ADMIN']"}],attrs:{"size":"small"},on:{"click":function($event){return _vm.create()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.create"))+" ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['ADMIN']),expression:"['ADMIN']"}],attrs:{"size":"small","disabled":_vm.selects.length===0},on:{"click":function($event){return _vm.del()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.delete"))+" ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['ADMIN']),expression:"['ADMIN']"}],attrs:{"size":"small"},on:{"click":function($event){return _vm.onGrant()}}},[_vm._v(_vm._s(_vm.$t("commons.button.authorize")))])],1)]},proxy:true}])},[_c('el-table-column',{attrs:{"type":"selection","fix":""}}),_c('el-table-column',{attrs:{"sortable":"","prop":"name","label":_vm.$t('commons.table.name'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{attrs:{"type":"info"},on:{"click":function($event){return _vm.openDetailPage(row)}}},[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"region","label":_vm.$t('automatic.region.name'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.region)+" ")]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"zones","label":_vm.$t('automatic.zone.name'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.zones),function(zoneName){return _c('span',{key:zoneName},[_vm._v(_vm._s(zoneName)+",")])})}}])}),(_vm.isAdmin)?_c('el-table-column',{attrs:{"sortable":"","prop":"projects","label":_vm.$t('project.project'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.projects),function(name){return _c('span',{key:name},[_vm._v(_vm._s(name)+",")])})}}],null,false,3350671603)}):_vm._e(),_c('el-table-column',{attrs:{"sortable":"","prop":"createdAt","label":_vm.$t('commons.table.create_time')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("datetimeFormat")(row.createdAt)))]}}])}),(_vm.isAdmin)?_c('fu-table-operations',{attrs:{"buttons":_vm.buttons,"label":_vm.$t('commons.table.action')}}):_vm._e()],1),_c('el-dialog',{attrs:{"title":_vm.$t('automatic.detail'),"visible":_vm.openDetail},on:{"update:visible":function($event){_vm.openDetail=$event}}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',{staticStyle:{"margin-top":"15px"},attrs:{"align":"center"}},[_c('table',{staticClass:"myTable",staticStyle:{"width":"90%"}},[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("commons.table.name")))]),_c('td',[_vm._v(_vm._s(_vm.item.name))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("automatic.region.name")))]),_c('td',[_vm._v(_vm._s(_vm.item.region))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("automatic.zone.name")))]),_c('td',_vm._l((_vm.item.zones),function(zone,index){return _c('span',{key:index},[_vm._v(_vm._s(zone)+",")])}),0)]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("automatic.plan.deploy_template")))]),(_vm.item.deployTemplate)?_c('td',[_vm._v(_vm._s(_vm.$t("automatic.plan." + _vm.item.deployTemplate)))]):_vm._e()]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("automatic.plan.master_model")))]),_c('td',[_vm._v(_vm._s(_vm.item.planVars.masterModel))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("automatic.plan.worker_model")))]),_c('td',[_vm._v(_vm._s(_vm.item.planVars.workerModel))])])])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.openDetail = false}}},[_vm._v(_vm._s(_vm.$t("commons.button.cancel")))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }